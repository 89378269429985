<template>
  <div class="page" v-if="mainPage">
    <!--标题-->
    <ls-title title="中医体质"></ls-title>

      <!-- 条件框 -->
      <div class="conddiv">
        <el-input v-model="condition"  placeholder="搜索用户姓名/身份证" prefix-icon="el-icon-search" style="width:200px;"></el-input>
        
        <el-date-picker v-model="dateTime" style="margin-left:5px;" type="daterange" range-separator="至" format="YYYY 年 MM 月 DD 日"
                value-format="YYYY-MM-DD" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>

        <el-select v-model="gradeValue" style="margin-left:5px;" placeholder="体质类型" size="large">
          <el-option v-for="item in gradeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button type="warning" style="margin-left:5px;" @click="search">搜索</el-button>
      </div>

      <!-- 表格 -->
      <el-table :data="dataList" border style="width: 100%;border-radius: 10px;" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
        <el-table-column prop="assessTime" label="生成时间" width="180" label-class-name="tablehead" align="center " />
        <el-table-column v-if="role == 1" prop="unitName" label="所属机构"  label-class-name="tablehead" align="center " />
        <el-table-column prop="userName" label="姓名" width="130" label-class-name="tablehead" align="center " />
        <el-table-column prop="sex" label="性别"  width="80" label-class-name="tablehead" align="center " >
          <template #default="scope">
            <span v-if="scope.row.sex == 1">男</span>
            <span v-if="scope.row.sex == 0">女</span>
          </template>
        </el-table-column>
        <el-table-column prop="idcard" label="身份证" width="180" label-class-name="tablehead" align="center " />
        <el-table-column prop="describe" label="体质评估" label-class-name="tablehead" align="center " />
        <el-table-column prop="source" label="来源" width="180" label-class-name="tablehead" align="center " />
        <el-table-column label="操作" label-class-name="tablehead" align="center " width="80">
          <template #default="scope">
            <span class="textbtn" @click="details(scope.row)">详情</span>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="pagination">
        <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
      </div>
  </div>
    <template v-if="detailsPage">
      <TcmDetails ref="childView" @cancel="cancelDetails"></TcmDetails>
    </template>
</template>

<script>
import {getTcmInfos} from '../../../api/api'
import until from '../../../until/until'
import session from "../../../store/store";
import TcmDetails from './TcmDetails.vue';
export default {
  components: {
    TcmDetails
  },
  data() {
    return {
      emptyText:'加载中',
      mainPage: true,
      detailsPage: false,
      condition: "",//姓名
      dateTime: '',//条件
      startTime:'',
      endTime:'',
      gradeValue: '',
      gradeOptions: [
        {value: '0',label: '全部' },
        {value: '1',label: '平和质'}, 
        {value: '2',label: '气虚质'}, 
        {value: '3',label: '阳虚质'},
        {value: '4',label: '阴虚质'},
        {value: '5',label: '痰湿质'}, 
        {value: '6',label: '湿热质'}, 
        {value: '7',label: '血淤质'},
        {value: '8',label: '气郁质'},
        {value: '9',label: '特禀质'}
      ],
      role: 0,
      currentPage: 1,
      size: 10,
      total: 0,//总数
      dataList: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.role = session.getLoginInfo().accountType;
      this.getTcmInfos();
    },
    search() {
      if (!until.isNULL(this.dateTime)) {
        this.startTime = this.dateTime[0],
        this.endTime = this.dateTime[1]
      }else {
        this.startTime = '',
        this.endTime = ''
      }
      this.getTcmInfos();
    },
    getTcmInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
        page:this.currentPage - 1,
        size:this.size,
        startTime:this.startTime,
        endTime:this.endTime,
        condition:this.condition
      }
      if (!until.isNULL(this.gradeValue)) {
        param.conditionType = this.gradeValue
      }else {
        param.conditionType = 0
      }
      getTcmInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.dataList = res.data.dataInfo;
        }else {
          this.total = 0;
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据"
        }
      })
    },

      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getTcmInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTcmInfos();
    },
    details(row) {
      this.mainPage = false;
      this.detailsPage= true;
      setTimeout(() => {
        this.$refs.childView.getDataWithUnit(row);
      }, 200);
    },
    cancelDetails() {
      this.mainPage = true;
      this.detailsPage= false;
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.conddiv {
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0px 2px 3px 4px var(--el-color-info-light);
  margin-bottom: 20px;
}
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
.textbtn {
  color: #285df0;
  cursor: pointer;
}
</style>
<style>
/* .el-input__prefix {
  top: 5px !important;
} */
.tablehead {
  color: white;
}
</style>