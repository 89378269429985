import {getTcmInfo} from '../../../../api/api'
import session from "../../../../store/store";
export default {
    data() {
        return {
            printObj: {
                id: "printMe",
                popTitle: "",
                extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
                type: "html"
            },
            userData: {
                name:'',
                age:'',
                sex:'',
            },
            tcmData:{
                uuid:'',
                describe:'',
                assessTime:'',
            },
            tcmResult:[],
        }
    },
    mounted() {
        this.init()
    },

    methods: {
        getDataWithUser(data) {
            this.userData.name = session.getCustomerData().name;
            this.userData.age = session.getCustomerData().age;
            if(1 == session.getCustomerData().sex) {
                this.userData.sex = '男';
            }else  {
                this.userData.sex = '女';
            }

            this.tcmData.uuid = data.uuid;
            this.tcmData.assessTime = data.assessTime;
            this.tcmData.describe = data.describe;
            this.getTcmInfo();
        },
        getDataWithUnit(data) {
            this.userData.name = data.userName;
            this.userData.age = data.age;
            if(1 == data.sex) {
                this.userData.sex = '男';
            }else  {
                this.userData.sex = '女';
            }

            this.tcmData.uuid = data.uuid;
            this.tcmData.assessTime = data.assessTime;
            this.tcmData.describe = data.describe;
            this.getTcmInfo();
        },
        cancel() {
            this.$emit("cancel")
        },

        /**
         * 初始化函数
         */
        init() {
            this.tizhiDiv(false)
            // setTimeout(() => {
            //     this.tizhiDiv(false)
            //     this.getTcmInfo()
            // }, 100);

        },
        getTcmInfo() {
            let param = {
                uuid: this.tcmData.uuid
            }
            getTcmInfo(param).then(res => {
                if (res.data.code == 200) {
                    this.tcmResult = res.data.dataInfo;
                    this.isdisblePage()
                }
            })
        },

       
        /**
         * 隐藏和显示结果页面
         */
        tizhiDiv(bol) {
            if (bol) {
                document.getElementById('tizhiDiv').style = 'display:block;'
            } else {
                document.getElementById('tizhiDiv').style = 'display:none;'
                document.getElementById('idA').style = 'display:none;'
                document.getElementById('idB').style = 'display:none;'
                document.getElementById('idC').style = 'display:none;'
                document.getElementById('idD').style = 'display:none;'
                document.getElementById('idE').style = 'display:none;'
                document.getElementById('idF').style = 'display:none;'
                document.getElementById('idG').style = 'display:none;'
                document.getElementById('idH').style = 'display:none;'
                document.getElementById('idI').style = 'display:none;'
            }
        },
        /**
         * 判断需要显示的页面
         */
        isdisblePage() {
            //显示结果页面
            this.tizhiDiv(true)
            let arr = this.tcmResult
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].tcmType == 'A') {
                    document.getElementById('idA').style = 'display:block;'
                } else if (arr[i].tcmType == 'B') {
                    document.getElementById('idB').style = 'display:block;'
                } else if (arr[i].tcmType == 'C') {
                    document.getElementById('idC').style = 'display:block;'
                } else if (arr[i].tcmType == 'D') {
                    document.getElementById('idD').style = 'display:block;'
                } else if (arr[i].tcmType == 'E') {
                    document.getElementById('idE').style = 'display:block;'
                } else if (arr[i].tcmType == 'F') {
                    document.getElementById('idF').style = 'display:block;'
                } else if (arr[i].tcmType == 'G') {
                    document.getElementById('idG').style = 'display:block;'
                } else if (arr[i].tcmType == 'H') {
                    document.getElementById('idH').style = 'display:block;'
                } else if (arr[i].tcmType == 'I') {
                    document.getElementById('idI').style = 'display:block;'
                }

            }

        }
    }
}