<template>
  <div class="page">
    <ls-title-header @back="cancel" title="报告详情"></ls-title-header>

    <div id="printMe" class="divboeder">
      <div class="printTitle"><span>中医体质评估报告</span></div>
      <div class="content">
        <!--用户基本信息-->
        <div class="userInfo">
          <div class="first_div">
            <div class="interval">
              <span class="prefix">姓名：</span>
              <span class="value">{{ userData.name }}</span>
            </div>
            <div class="interval">
              <span class="prefix">性别：</span>
              <span class="value">{{ userData.sex }}</span>
            </div>
            <div class="interval">
              <span class="prefix">年龄：</span>
              <span class="value">{{ userData.age }}</span
              ><span>岁</span>
            </div>
          </div>
          <div style="display: flex">
            <!-- <div class="interval">
              <span class="prefix">检测项目：</span>
              <span class="value">体质判定</span>
            </div> -->
            <div class="interval">
              <span class="prefix">检测时间：</span>
              <span class="value">{{ tcmData.assessTime }}</span>
            </div>
            <div class="intervalV1">
              <span class="prefix">判定结果：</span>
              <span class="value" style="margin-right: 15px">{{tcmData.describe}}</span>
            </div>
          </div>
        </div>

        <!--体质特征-->
        <div id="tizhiDiv">
          <!--和平质体质特征A型-->
          <div id="idA">
            <div class="bodyType">
              <p>和平质体质特征</p>
            </div>
            <div class="judge">
              <table class="table" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td>总体特征</td>
                  <td>
                    阴阳气血调和，以体态适中、面色红润、精力充沛等为主要特征。
                  </td>
                </tr>
                <tr>
                  <td>常见表现</td>
                  <td>
                    面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，芥薄白，脉和缓有力。
                  </td>
                </tr>
                <tr>
                  <td>形体特征</td>
                  <td>体型匀称健壮。</td>
                </tr>
                <tr>
                  <td>心理特征</td>
                  <td>性格随和开朗。</td>
                </tr>
                <tr>
                  <td>发病倾向</td>
                  <td>平素患病较少。</td>
                </tr>
                <tr>
                  <td>对外界环境适应能力</td>
                  <td>对自然环境和社会环境适应能力较强。</td>
                </tr>
              </table>
            </div>
          </div>

          <!--气虚质体质特征B型-->
          <div id="idB">
            <div class="bodyType">
              <p>气虚质体质特征</p>
            </div>
            <div class="judge">
              <table class="table" border="1">
                <tr>
                  <td>总体特征</td>
                  <td>元气不足，以疲乏,气短，自汗 等气虚表现为主要特征。</td>
                </tr>
                <tr>
                  <td>常见表现</td>
                  <td>
                    平素语音低弱，气短懒言，容易疲乏，精神不振，易出汗，舌淡红，舌边有齿痕，脉弱。
                  </td>
                </tr>
                <tr>
                  <td>形体特征</td>
                  <td>肌肉松软不实。</td>
                </tr>
                <tr>
                  <td>心理特征</td>
                  <td>性格内向，不喜冒险。</td>
                </tr>
                <tr>
                  <td>发病倾向</td>
                  <td>易患感冒，内脏下垂等病，病后康复缓慢。</td>
                </tr>
                <tr>
                  <td>对外界环境适应能力</td>
                  <td>不耐受风、寒、暑、湿邪。</td>
                </tr>
              </table>
            </div>
          </div>

          <!--阳虚质体质特征C型-->
          <div id="idC" class="physical">
            <div class="bodyType">
              <p>阳虚质体质特征</p>
            </div>
            <div class="judge">
              <table class="table" border="1">
                <tr>
                  <td>总体特征</td>
                  <td>阳气不足，以畏寒怕冷、 手足不温等虚寒表现为主要特征。</td>
                </tr>
                <tr>
                  <td>常见表现</td>
                  <td>
                    平素畏冷，手足不温，喜热饮食，精神不振，舌淡胖嫩，脉沉迟。
                  </td>
                </tr>
                <tr>
                  <td>形体特征</td>
                  <td>肌肉松软不实。</td>
                </tr>
                <tr>
                  <td>心理特征</td>
                  <td>性格多沉静、内向。</td>
                </tr>
                <tr>
                  <td>发病倾向</td>
                  <td>易患痰饮、肿胀、泄泻等病；感邪易从寒化。</td>
                </tr>
                <tr>
                  <td>对外界环境适应能力</td>
                  <td>耐夏不耐冬；易感风、寒、湿邪。</td>
                </tr>
              </table>
            </div>
          </div>

          <!--阴虚质体质特征D型-->
          <div id="idD" class="physical">
            <div class="bodyType">
              <p>阴虚质体质特征</p>
            </div>
            <div class="judge">
              <table class="table" border="1">
                <tr>
                  <td>总体特征</td>
                  <td>阴液亏少，以口燥咽干、手足心热等虚热表现为主要特征。</td>
                </tr>
                <tr>
                  <td>常见表现</td>
                  <td>
                    手足心热，口燥咽干，鼻微干，喜冷饮，
                    大便干燥，舌红少津，脉细数。
                  </td>
                </tr>
                <tr>
                  <td>形体特征</td>
                  <td>体形偏瘦。</td>
                </tr>
                <tr>
                  <td>心理特征</td>
                  <td>性情急躁，外向好动，活泼。</td>
                </tr>
                <tr>
                  <td>发病倾向</td>
                  <td>易患虚劳、失精、不寐等病；感邪易从热化。</td>
                </tr>
                <tr>
                  <td>对外界环境适应能力</td>
                  <td>耐冬不耐夏；不耐受暑、热、燥邪。</td>
                </tr>
              </table>
            </div>
          </div>

          <!--痰湿质体质特征E型-->
          <div id="idE" class="physical">
            <div class="bodyType">
              <p>痰湿质体质特征</p>
            </div>
            <div class="judge">
              <table class="table" border="1">
                <tr>
                  <td>总体特征</td>
                  <td>
                    痰湿凝聚，以形体肥胖、腹部肥满、口黏苔腻等痰湿表现为主要特征。
                  </td>
                </tr>
                <tr>
                  <td>常见表现</td>
                  <td>
                    面部皮肤油脂较多，多汗且黏，胸闷，痰多，口黏腻或甜，喜食肥甘甜黏，苔腻脉滑。
                  </td>
                </tr>
                <tr>
                  <td>形体特征</td>
                  <td>体形肥胖，腹部肥满松软。</td>
                </tr>
                <tr>
                  <td>心理特征</td>
                  <td>性格偏温和、稳重，多善于忍耐。</td>
                </tr>
                <tr>
                  <td>发病倾向</td>
                  <td>易感消渴、中风、胸痹等病。</td>
                </tr>
                <tr>
                  <td>对外界环境适应能力</td>
                  <td>对梅雨季节及湿重环境适应能力差。</td>
                </tr>
              </table>
            </div>
          </div>

          <!--湿热质体质特征F型-->
          <div id="idF" class="physical">
            <div class="bodyType">
              <p>湿热质体质特征</p>
            </div>
            <div class="judge">
              <table class="table" border="1">
                <tr>
                  <td>总体特征</td>
                  <td>
                    湿热内蕴，以面垢油光、口苦、苔黄腻等湿热表现为主要特征。
                  </td>
                </tr>
                <tr>
                  <td>常见表现</td>
                  <td>
                    面垢油光，易生痤疮，口苦口干，身重困倦，大便黏滞不畅或燥结，小便短黄，男性易阴囊潮湿，女性易带下增多，舌质偏红，苔黄腻，脉滑数。
                  </td>
                </tr>
                <tr>
                  <td>形体特征</td>
                  <td>形体中等或偏瘦。</td>
                </tr>
                <tr>
                  <td>心理特征</td>
                  <td>容易心烦急躁。</td>
                </tr>
                <tr>
                  <td>发病倾向</td>
                  <td>易患疮疖、黄疽、热淋等病。</td>
                </tr>
                <tr>
                  <td>对外界环境适应能力</td>
                  <td>对夏末秋初湿热气候，湿重或气温偏高环境较难适应。</td>
                </tr>
              </table>
            </div>
          </div>

          <!--血瘀质体质特征G型-->
          <div id="idG" class="physical">
            <div class="bodyType">
              <p>血瘀质体质特征</p>
            </div>
            <div class="judge">
              <table class="table" border="1">
                <tr>
                  <td>总体特征</td>
                  <td>血行不畅，以肤色晦黯、舌质紫黯等血瘀表现为主要特征。</td>
                </tr>
                <tr>
                  <td>常见表现</td>
                  <td>
                    肤色晦黯，色素沉着，容易出现瘀斑，口唇黯淡，舌黯或有瘀点，舌下络脉紫黯或增粗，脉涩。
                  </td>
                </tr>
                <tr>
                  <td>形体特征</td>
                  <td>胖瘦均见。</td>
                </tr>
                <tr>
                  <td>心理特征</td>
                  <td>易烦，健忘。</td>
                </tr>
                <tr>
                  <td>发病倾向</td>
                  <td>易患癥瘕及痛证、血证等。</td>
                </tr>
                <tr>
                  <td>对外界环境适应能力</td>
                  <td>不耐受寒邪。</td>
                </tr>
              </table>
            </div>
          </div>

          <!--气郁质体质特征H型-->
          <div id="idH" class="physical">
            <div class="bodyType">
              <p>气郁质体质特征</p>
            </div>
            <div class="judge">
              <table class="table" border="1">
                <tr>
                  <td>总体特征</td>
                  <td>气机郁滞，以神情抑郁、忧虑脆弱等气郁表现为主要特征。</td>
                </tr>
                <tr>
                  <td>常见表现</td>
                  <td>神情抑郁，情感脆弱，烦闷不乐，舌淡红，苔薄白，脉弦。</td>
                </tr>
                <tr>
                  <td>形体特征</td>
                  <td>形体瘦者为多。</td>
                </tr>
                <tr>
                  <td>心理特征</td>
                  <td>性格内向不稳定、敏感多虑。</td>
                </tr>
                <tr>
                  <td>发病倾向</td>
                  <td>易患脏躁、梅核气、百合病及郁证等。</td>
                </tr>
                <tr>
                  <td>对外界环境适应能力</td>
                  <td>对精神刺激适应能力较差；不适应阴雨天气。</td>
                </tr>
              </table>
            </div>
          </div>

          <!--特禀质体质特征I型-->
          <div id="idI" class="physical">
            <div class="bodyType">
              <p>特禀质体质特征</p>
            </div>
            <div class="judge">
              <table class="table" border="1">
                <tr>
                  <td>总体特征</td>
                  <td>先天失常，以生理缺陷、过敏反应等为主要特征。</td>
                </tr>
                <tr>
                  <td>常见表现</td>
                  <td>
                    过敏体质者常见哮喘、风团、咽痒、鼻塞、喷嚏等；患遗传性疾病者有垂直遗传、先天性、家族性特征；患胎传性疾病者具有母体影响胎儿个体生长发育及相关疾病特征。
                  </td>
                </tr>
                <tr>
                  <td>形体特征</td>
                  <td>
                    过敏体质者一般无特殊；先天禀赋异常者或有畸形，或有生理缺陷。
                  </td>
                </tr>
                <tr>
                  <td>心理特征</td>
                  <td>随禀质不同情况各异。</td>
                </tr>
                <tr>
                  <td>发病倾向</td>
                  <td>
                    过敏体质者易患哮喘、荨麻疹、花粉症及药物过敏等；遗传性疾病如血友病、先天愚型等；胎传性疾病如五迟(立迟、行迟、发迟、齿迟和语迟)、五软(头软、项软、手足软、肌肉软、口软)、解颅、胎惊、胎痛等。
                  </td>
                </tr>
                <tr>
                  <td>对外界环境适应能力</td>
                  <td>
                    适应能力差，如过敏体质者对易致过敏季节适应能力差，易引发宿疾。
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-button type="warning" v-print="printObj" style="margin: 20px">打印</el-button>
    </div>
  </div>
</template>

<script src="./js/tcm-details.js"></script>
<style src="./css/tcm-details.css" scoped></style>

<!-- // 去除打印的页眉页脚 -->
<style media="print">
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}
</style>
